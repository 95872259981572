.w-30{
    width:30% !important;
}

.w-28{
    width:28% !important;
}


.br-30px{
    border-radius:30px !important;
}

.px-6{
    padding-left:4rem !important;
    padding-right:4rem !important;
}


.w-65{
    width:65% !important;
}


.skip-pay-button{
    padding:.3rem 1rem;
    font-size:1.3rem;
    border-radius:20px;
    border:none;
    width:120px;
    color:white;
    background-color:rgba(0, 26, 255,0.5);
}




@media (min-width:992px){
    .w-lg-30{
        width:30% !important;
    }
}


@media (min-width:768px){
    .w-md-30{
        width:30% !important;
    }
}

@media (max-width:576px){
    .my-xsm-3{
        margin-top:1rem;
        margin-bottom:1rem;
    }
}


.d-btn{
    padding:.5rem 1.6rem;
    font-size:1.2rem;
    border-radius:20px;
    border:none;
    /* width:195px; */
    color:white;
    background-color:var(--collection-1-primary-main);
    box-shadow: var(--bs-box-shadow-lg)!important;
}

