.blog-nav a{
    text-decoration: none;
    color:black;
    border-bottom:2px solid white;
}

.blog-nav a:hover{
    border-bottom:2px solid var(--collection-1-primary-main);
}

.blog-card-container{
   display: grid;
}