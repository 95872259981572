.header {
    align-items: flex-start;
    display: flex;
    gap: 24px;
    justify-content: center;
    padding: 100px 200px;
    position: relative;
}

.header .element {
    height: 742px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 1440px;
}

.header .title {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 8px;
    position: relative;
}

.header .improve-your-content {
    align-self: stretch;
    color: var(--collection-1-primary-main);
    /* font-family: "Inter-Bold", Helvetica; */
    font-family: "Inter", Helvetica;
    font-size:var(--h-header-font);
    font-weight: 700;
    letter-spacing: 0;
    line-height: 60px;
    margin-top: -1px;
    position: relative;
    text-align: center;
}

.header .text-wrapper {
    color: #0a0a0a;
}

.header .span {
    color: var(--collection-1-primary-main);
}

.header .discover-creative {
    align-self: stretch;
    color: var(--collection-1-neutral-90);
    /* font-family: "Inter-Regular", Helvetica; */
    font-family: "Inter", Helvetica;
    font-size: var(--p-font);
    font-weight: 400;
    letter-spacing: 0;
    line-height: 34px;
    position: relative;
    text-align: center;
}

@media (max-width:600px) {
    .height-fit{
        height: fit-content !important;
    }
    .improve-your-content{
        line-height: 2.8rem !important;
    }
    .discover-creative{
        line-height: 1.7rem !important;
    }
}