.b-btn{
    padding:.5rem 1.6rem;
    font-size:1.2rem;
    border-radius:20px;
    border:none;
    width:195px;
    color:white;
    background-color:var(--collection-1-primary-main);
    box-shadow: var(--bs-box-shadow-lg)!important;
}


.bg-grey{
    background-color:rgba(217, 217, 217,0.5)
}

.w-20px{
    width:20px;
    height:auto;
}

.prof-img{
    width:80px;
    border-radius:50%;
    border:10px solid rgba(217, 217, 217,0.5);
    left:-50px;
    top:-12px;
}

p{
    font-size: 1.3rem;
}

button{
    font-size: 1.3rem;
}

.w-fc{
    width:fit-content;
}

.w-60{
    width:60% !important;
}

.w-30{
    width:30% !important;
}
.w-29{
    width:29% !important;
}

.w-72{
    width:72% !important;
}

.w-68{
    width:68% !important;
}

.h-3px{
    height:3px !important;
}

.br-17px{
    border-radius: 17px !important;
}

@media (max-width:575px){
    .mt-sm-50{
        margin-top:50px;
    }
    .w-100px{
        width:100px;
    }
}

@media (min-width:998px){
    .w-lg-68{
        width:68% !important;
    }
    .w-lg-29{
        width:29% !important;
    }
}

@media (max-width:768px){
    .bttn{
        width:75% !important;
    }
}

@media (min-width:768px){
    .bttn{
        width:fit-content !important;
    } 
}