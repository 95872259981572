.advanced-analytics {
    align-items: flex-start;
    background-color: #fafbff;
    display: flex;
    padding: 100px 150px 0px;
    position: relative;
}

.advanced-analytics .content {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 24px;
    position: relative;
}

.advanced-analytics .title {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
    width: 100%;
}

.advanced-analytics .text-wrapper {
    align-self: stretch;
    color: var(--collection-1-success-main);
    font-family: "Inter", Helvetica;
    /* font-size: 20px; */
    font-size:var(--h-tag-font);
    font-weight: 500;
    letter-spacing: 0;
    line-height: 27.2px;
    margin-top: -1px;
    position: relative;
}

.advanced-analytics .div {
    align-self: stretch;
    color: var(--collection-1-neutral-100);
    font-family: "Inter", Helvetica;
    /* font-size: 32px; */
    font-size:var(--h-font);
    font-weight: 700;
    letter-spacing: 0;
    line-height: 48px;
    position: relative;
}

.advanced-analytics .p {
    align-self: stretch;
    color: var(--collection-1-neutral-90);
    font-family: "Inter", Helvetica;
    /* font-size: 20px; */
    font-size:var(--p-font);
    font-weight: 400;
    letter-spacing: 0;
    line-height: 27.2px;
    position: relative;
}

.advanced-analytics .content-2 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 32px;
    position: relative;
    width: 100%;
}

.advanced-analytics .line {
    height: 70%;
    left: 15px;
    position: absolute;
    top: 27px;
    width: 2px;
}

.advanced-analytics .feature {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 12px;
    position: relative;
    width: 100%;
}

.advanced-analytics .circle {
    background-image: url(../../../assets/Circle.svg);
    background-size: 100% 100%;
    box-shadow: var(--shadow-1);
    height: 32px;
    position: relative;
    width: 32px;
    border-radius: 50% !important;
}

.advanced-analytics .ellipse {
    background-color: #fafbff;
    border-radius: 8px;
    height: 16px;
    left: 8px;
    position: relative;
    top: 8px;
    width: 16px;
}

.advanced-analytics .text-wrapper-2 {
    color: var(--collection-1-neutral-90);
    flex: 1;
    font-family: "Inter", Helvetica;
    font-size:var(--p-font);
    font-weight: 500;
    letter-spacing: 0;
    line-height: 27.2px;
    margin-top: -1px;
    position: relative;
}

.advanced-analytics .ellipse-wrapper {
    background-image: url(../../../assets/Circle.svg);
    background-size: 100% 100%;
    box-shadow: var(--shadow-1);
    height: 32px;
    position: relative;
    width: 32px;
    border-radius: 50% !important;
}

.advanced-analytics .div-wrapper {
    background-image: url(../../../assets/Circle.svg);
    background-size: 100% 100%;
    box-shadow: var(--shadow-1);
    height: 32px;
    position: relative;
    width: 32px;
    border-radius: 50% !important;
}

.advanced-analytics .group {
    height: 354.36px;
    position: relative;
    width: 653px;
}

.advanced-analytics .diagram {
    background-color: var(--collection-1-neutral-10);
    border: 1.19px solid;
    border-color: var(--collection-1-neutral-20);
    border-radius: 16px;
    box-shadow: var(--shadow-1);
    height: 209px;
    left: 84px;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 569px;
}

.advanced-analytics .ellipse-2 {
    border: 23.78px solid;
    border-color: #3870ff33;
    border-radius: 101.67px;
    height: 203px;
    left: 259px;
    position: absolute;
    top: -125px;
    width: 203px;
}

.advanced-analytics .overlap {
    height: 276px;
    left: -64px;
    position: absolute;
    top: 73px;
    width: 203px;
}

.advanced-analytics .ellipse-3 {
    border: 23.78px solid;
    border-color: #3870ff33;
    border-radius: 101.67px;
    height: 203px;
    left: 0;
    position: absolute;
    top: 73px;
    width: 203px;
}

.advanced-analytics .overlap-wrapper {
    height: 113px;
    left: 88px;
    position: absolute;
    top: 0;
    width: 113px;
}

.advanced-analytics .overlap-group {
    height: 113px;
    position: relative;
}

.advanced-analytics .overlap-group-wrapper {
    height: 113px;
    left: 0;
    position: absolute;
    top: 0;
    width: 113px;
}

.advanced-analytics .overlap-group-2 {
    border: 19.03px solid;
    border-color: var(--collection-1-primary-main);
    border-radius: 66px;
    height: 132px;
    left: -29px;
    position: relative;
    top: -29px;
    width: 132px;
}

.advanced-analytics .streaming {
    height: 66px;
    left: -38px;
    position: absolute;
    top: -38px;
    width: 66px;
}

.advanced-analytics .reaction {
    height: 66px;
    left: -38px;
    position: absolute;
    top: 28px;
    width: 66px;
}

.advanced-analytics .title-2 {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    left: 17px;
    position: absolute;
    top: 45px;
}

/* .advanced-analytics .text-wrapper-3 {
    color: var(--collection-1-neutral-90);
    font-family: "Inter-Regular", Helvetica;
    font-size: 9.5px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.19px;
    position: relative;
    text-align: center;
    width: fit-content;
} */

.advanced-analytics .text-wrapper-4 {
    color: var(--collection-1-neutral-90);
    font-family: "Inter", Helvetica;
    font-size: 9.5px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    text-align: center;
    width: fit-content;
}

.advanced-analytics .text-wrapper-5 {
    color: var(--collection-1-neutral-100);
    font-family: "Inter", Helvetica;
    font-size: 16.6px;
    font-weight: 600;
    left: 24px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 23px;
}

.advanced-analytics .data {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 9.51px;
    left: 181px;
    position: absolute;
    top: 99px;
    width: 157px;
}

.advanced-analytics .div-2 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 9.51px;
    position: relative;
    width: 100%;
}

.advanced-analytics .education {
    background-color: var(--collection-1-primary-main);
    border-radius: 7.13px;
    height: 14.27px;
    position: relative;
    width: 14.27px;
}

.advanced-analytics .text-wrapper-6 {
    color: var(--collection-1-neutral-100);
    flex: 1;
    font-family: "Inter", Helvetica;
    font-size: 11.9px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.05px;
    position: relative;
}

.advanced-analytics .text-wrapper-7 {
    color: var(--collection-1-primary-main);
    flex: 1;
    font-family: "Inter", Helvetica;
    font-size: 11.9px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.05px;
    position: relative;
    text-align: right;
}

.advanced-analytics .streaming-2 {
    background-color: var(--collection-1-success-main);
    border-radius: 7.13px;
    height: 14.27px;
    position: relative;
    width: 14.27px;
}

.advanced-analytics .text-wrapper-8 {
    color: var(--collection-1-success-main);
    flex: 1;
    font-family: "Inter", Helvetica;
    font-size: 11.9px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.05px;
    position: relative;
    text-align: right;
}

.advanced-analytics .reaction-2 {
    background-color: var(--collection-1-warning-main);
    border-radius: 7.13px;
    height: 14.27px;
    position: relative;
    width: 14.27px;
}

.advanced-analytics .text-wrapper-9 {
    color: var(--collection-1-danger-main);
    flex: 1;
    font-family: "Inter", Helvetica;
    font-size: 11.9px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.05px;
    position: relative;
    text-align: right;
}

.advanced-analytics .video-wrapper {
    box-shadow: var(--shadow-1);
    height: 117px;
    left: 354px;
    position: absolute;
    top: 238px;
    width: 299px;
}

.advanced-analytics .video {
    background-color: var(--collection-1-neutral-10);
    border: 1.19px solid;
    border-color: var(--collection-1-neutral-20);
    border-radius: 16px;
    height: 117px;
    overflow: hidden;
    position: relative;
}

.advanced-analytics .text-wrapper-10 {
    color: var(--collection-1-neutral-90);
    font-family: "Inter", Helvetica;
    font-size: var(--p-miny);
    font-weight: 500;
    left: 10px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 14px;
    white-space: nowrap;
}

.advanced-analytics .text-wrapper-11 {
    color: #000000;
    font-family: "Inter", Helvetica;
    font-size: 23.8px;
    font-weight: 700;
    left: 10px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 42px;
}

.advanced-analytics .percen {
    align-items: center;
    display: inline-flex;
    gap: 4.76px;
    left: 10px;
    position: absolute;
    top: 84px;
}

.advanced-analytics .capa {
    height: 14.27px;
    position: relative;
    width: 14.27px;
}

.advanced-analytics .text-wrapper-12 {
    color: var(--collection-1-primary-main);
    font-family: "Inter", Helvetica;
    font-size: var(--p-light);
    font-weight: 700;
    letter-spacing: 0;
    line-height: 20.2px;
    margin-top: -1.19px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.advanced-analytics .trafic {
    height: 34px;
    left: 108px;
    position: absolute;
    top: 77px;
    width: 72px;
}

.advanced-analytics .group-2 {
    box-shadow: var(--shadow-1);
    height: 117px;
    left: 0;
    position: absolute;
    top: 238px;
    width: 299px;
}

/* .scale{
   
} */

@media (max-width: 790px) {
    .scale {
        transform: scale(0.8) !important;
    }
}

.advanced-analytics .text-wrapper-3 {
    align-self: stretch;
    color: var(--collection-1-primary-hover);
    font-family: "Inter", Helvetica;
    font-size:var(--p-light);
    /* font-size: 20px; */
    font-weight: 600;
    letter-spacing: 0;
    line-height: 34px;
    margin-top: -1px;
    position: relative;
}