.company {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 56px 150px;
    position: relative;
}

.company .trusted-by-various {
    align-self: stretch;
    color: var(--collection-1-primary-hover);
    font-family: "Inter", Helvetica;
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-align: center;
}

.company .div {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    position: relative;
    width: 100%;
}

.company .google-icon {
    /* height: 56.33px; */
    object-fit: cover;
    position: relative;
    width: 170px;
}

.company .youtube {
    height: 38.92px;
    position: relative;
    width: 170px;
}

.company .instagram {
    height: 48.13px;
    object-fit: cover;
    position: relative;
    width: 170px;
}

.company .facebook {
    height: 33.8px;
    position: relative;
    width: 170px;
}

