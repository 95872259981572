body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: "Inter", Helvetica !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  width:100%;
  margin:auto;
}

*{
  font-family: "Inter", Helvetica !important;
} 
/* *{
  font-family: comfortaa;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div {
  white-space: pre-wrap !important;
  white-space: -moz-pre-wrap !important;
  white-space: -pre-wrap !important;
  white-space: -o-pre-wrap !important;
  word-wrap: break-word !important;
}


.moving-animater {
  animation: onLoadMover 0.5s ease-in-out alternate; /* Animation properties */
}


@keyframes onLoadMover {
  from {
      transform: translateY(15px); 
  }
  to {
      transform: translateY(0); 
  }
}

.ant-layout-sider-children{
  position: relative !important;
}