.feature-section {
    align-items: flex-start;
    background-color: #fafbff;
    display: flex;
    flex-direction: column;
    gap: 56px;
    justify-content: center;
    padding: 100px 150px 0px;
    position: relative;
}

.feature-section .title {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    position: relative;
    width: 100%;
}

.feature-section .div {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
}

.feature-section .text-wrapper {
    align-self: stretch;
    color: var(--collection-1-success-main);
    /* font-family: "Inter-Medium", Helvetica; */
    font-family: "Inter", Helvetica;
    /* font-size: 16px; */
    font-size:var(--h-tag-font);
    font-weight: 500;
    letter-spacing: 0;
    line-height: 27.2px;
    margin-top: -1px;
    position: relative;
}

.feature-section .discover {
    align-self: stretch;
    color: var(--collection-1-neutral-100);
    /* font-family: "Inter-Bold", Helvetica; */
    font-family: "Inter", Helvetica;
    font-size: 2.1rem;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 48px;
    position: relative;
}

.feature-section .span {
    color: #0a0a0a;
}

.feature-section .text-wrapper-2 {
    color: #ff6d38;
}

.feature-section .embark-on-an {
    color: var(--collection-1-neutral-90);
    flex: 1;
    /* font-family: "Inter-Regular", Helvetica; */
    font-family: "Inter", Helvetica;
    font-size: var(--p-font);
    font-weight: 400;
    letter-spacing: 0;
    line-height: 30.6px;
    margin-top: -1px;
    position: relative;
}

.feature-section .content {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 32px;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.feature-section .div-2 {
    align-items: flex-start;
    align-self: stretch;
    background-color: var(--collection-1-neutral-10);
    border: 1px solid;
    border-color: var(--collection-1-neutral-50);
    border-radius: 6px;
    box-shadow: var(--shadow-1);
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 20px;
    justify-content: center;
    overflow: hidden;
    padding: 20px;
    position: relative;
}

.feature-section .img {
    flex: 0 0 auto;
    position: relative;
}

.feature-section .content-2 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 100%;
}


.feature-section .text-wrapper-3 {
    align-self: stretch;
    color: var(--collection-1-primary-hover);
    /* font-family: "Inter-SemiBold", Helvetica; */
    font-family: "Inter", Helvetica;
    font-size:var(--p-light);
    /* font-size: 20px; */
    font-weight: 600;
    letter-spacing: 0;
    line-height: 34px;
    margin-top: -1px;
    position: relative;
}

.feature-section .p {
    align-self: stretch;
    color: var(--collection-1-neutral-90);
    /* font-family: "Inter-Regular", Helvetica; */
    font-family: "Inter", Helvetica;
    /* font-size: 16px; */
    font-size:var(--p-light);
    font-weight: 400;
    letter-spacing: 0;
    line-height: 27.2px;
    position: relative;
}


.feature-section .data-driven {
    align-items: flex-start;
    background-color: var(--collection-1-neutral-10);
    border: 1px solid;
    border-color: var(--collection-1-neutral-50);
    border-radius: 6px;
    box-shadow: var(--shadow-1);
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 20px;
    justify-content: center;
    overflow: hidden;
    padding: 20px;
    position: relative;
}


.div-movement {
    left: 0;
    position: relative;
    animation: mover 0.8s ease-in-out;
}

#div-movement1 {
    position: relative;
    /* left: -1000px; */
    left:0;
}

#div-movement2 {
    position: relative;
    /* left: -1500px; */
    left:0;
}

#div-movement3 {
    position: relative;
    /* left: -2000px; */
    left:0;
}

@keyframes mover {
    0% {
        left: -1000px; /* Start from -5000px */
    }
    100% {
        left: 0; /* Move to 0px */
    }
}

/* .div-movement:hover {
    animation: mover 3s ease-in-out; /* Adjust the animation duration and easing function 
} */
