.loading-spinner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .loading-spinner .loader-spin-container {
    width: 100px; /* Adjust the width of the loader container */
    height: 50px; /* Adjust the height of the loader container */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .loading-spinner .loader-spin {
    border: 4px solid #f3f3f3 !important;
    border-top: 4px solid var(--collection-1-primary-main) !important;
    border-radius: 50% !important;
    width: 30px !important; /* Adjust the size of the loader */
    height: 30px !important; /* Adjust the size of the loader */
    animation: spin 1s linear infinite !important;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  