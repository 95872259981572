.App {
  height:100%;
  margin:auto;
}


::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 17px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

canvas{
  background-color:white !important;
}

.slick-prev{
  width: 30px !important;
  height: 30px !important;
  background-color: rgba(0, 26, 255,0.5) !important;
  border-radius: 50% !important;
  z-index: 1;
}


.max-1440{
  max-width:1440px;
  margin:auto;
}

.bg-light-st{
  background-color: #fafbff;
}

.slick-next{
  width: 30px !important;
  height: 30px !important;
  background-color: rgba(0, 26, 255,0.5) !important;
  border-radius: 50% !important;
  z-index: 1;
}


@media (max-width:768px){
.react-bootstrap-table{
  width:fit-content !important;
}
}

.react-bootstrap-table{
  margin: 0;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}


.rcw-conversation-container .rcw-header, .rcw-launcher{
    background-color:var(--collection-1-primary-main) !important;
}

.rcw-messages-container, .rcw-sender{
  padding:10px 20px;
}


/* .rcw-widget-container{
  z-index:1000 !important;
} */

.rcw-close-widget-container{
  z-index:1 !important;
}


.ant-layout-sider-children{
  display: flex !important;
  justify-content: space-between !important;
  flex-direction: column !important;
}


.chat-user{
  background-color: #e6f7ff !important;
}

.chat-bot{
  background-color: blue !important ;
}