.h-600{
    height:fit-content;
}

.bg-grey{
    background-color:rgba(217, 217, 217,0.5)
}

.px-3rem{
    padding-left:3rem;
    padding-right:3rem;
}

.px-5rem{
    padding-left:5rem;
    padding-right:5rem;
}

.s-in-btn{
    padding:.5rem 1.8rem;
    font-size:1.3rem;
    border-radius:20px;
    border:none;
    width:225px;
    color:white;
    background-color:var(--collection-1-primary-main);
}

.s-in-g-button{
    padding:.2rem  auto;
    font-size:1.3rem;
    border-radius:20px;
    border:none;
    color:rgba(0,0,0,0.5)
}

.w-100p{
    width:100%;
}

.col-blue{
    color:blue;
}

.br-20px{
    border-radius:20px;
}

.mlr-auto{
    margin-left:auto;
    margin-right:auto;
}

.g-img{
    width:20px;
    height:auto;
    margin-left:.5em;
}


.p-img{
    width:40px;
    height:auto;
    margin-left:.5em;
}

.logo-circle{
    width:75px;
    height:75px;
    background-color:rgba(217, 217, 217,0.5);
    border-radius:50%;
    margin-left:auto;
    margin-right:auto
}

.body-style{
    background-color:rgba(217, 217, 217,0.5);
    min-height:100vh;
    width:auto;
    margin:0;
    padding:10px;
    font-size:20px
  }


input[type=radio]{
    
    /* width:100px;
    background-color: blue;
    border:3px solid yellow;
    scale:1.8;
    border:none; */

    appearance: none;
    padding: 10px;
    background-color:rgba(0, 26, 255,0.1);
    border-radius: 50%;
    
}

input[type=radio]:checked{
    /* color:rgba(0, 26, 255,0.7); */
    border:5px solid rgba(0, 26, 255,0.1);
}

.line-img{
    max-width:100%;
}

.w-45{
    width:45% !important;
}