.question-container{
    background-color:rgba(0,0,0,0.4);
    position:fixed;
    z-index: 1000;
    height: 100vh;
    width: 100vw;
    overflow: none !important;
    display: flex;
    justify-content: center;
    align-items: center;
    top:0;
    left:0;
}


.script-container-questionaire{
    max-height:600px !important;
    overflow: scroll !important;
}


.script-button-container{
    display:flex;
    gap:3rem;
    justify-content: space-between;
}

.script-button-container :first-child{
    flex:1 1 50px !important;
}

.script-button-container :nth-child(2){
    flex:1 1 50px !important;
}
