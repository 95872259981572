.personalized-content {
    align-items: center;
    background-color: #fafbff;
    display: flex;
    gap: 131px;
    padding: 100px 150px 0px;
    position: relative;
}

.personalized-content .content {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 24px;
    justify-content: center;
    position: relative;
}

.personalized-content .title {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
    width: 100%;
}

.personalized-content .text-wrapper {
    align-self: stretch;
    color: var(--collection-1-success-main);
    /* font-family: "Inter-Medium", Helvetica; */
    font-family: "Inter", Helvetica;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 27.2px;
    margin-top: -1px;
    position: relative;
}

.personalized-content .div {
    align-self: stretch;
    color: var(--collection-1-neutral-100);
    /* font-family: "Inter-Bold", Helvetica; */
    font-family: "Inter", Helvetica;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 48px;
    position: relative;
}

.personalized-content .p {
    align-self: stretch;
    color: var(--collection-1-neutral-90);
    /* font-family: "Inter-Regular", Helvetica; */
    font-family: "Inter", Helvetica;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 27.2px;
    position: relative;
}

.personalized-content .content-2 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    /* gap: 32px; */
    position: relative;
    width: 100%;
}

.personalized-content .line {
    height: 144px;
    left: 15px;
    position: absolute;
    top: 27px;
    width: 2px;
}

.personalized-content .feature {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 12px;
    position: relative;
    width: 100%;
}

.personalized-content .circle {
    /* background-image: url(./background.svg); */
    background-size: 100% 100%;
    box-shadow: var(--shadow-1);
    height: 32px;
    position: relative;
    width: 32px;
}

.personalized-content .ellipse {
    background-color: #fafbff;
    border-radius: 8px;
    height: 16px;
    left: 8px;
    position: relative;
    top: 8px;
    width: 16px;
}

.personalized-content .text-wrapper-2 {
    color: var(--collection-1-neutral-90);
    flex: 1;
    /* font-family: "Inter-Medium", Helvetica; */
    font-family: "Inter", Helvetica;
    font-size:20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 27.2px;
    margin-top: -1px;
    position: relative;
}

.personalized-content .ellipse-wrapper {
    /* background-image: url(./image.svg); */
    background-size: 100% 100%;
    box-shadow: var(--shadow-1);
    height: 32px;
    position: relative;
    width: 32px;
}
/* 
.personalized-content .text-wrapper-3 {
    color: var(--collection-1-neutral-90);
    flex: 1;
    font-family: "Inter-Medium", Helvetica;
    font-size:20px; 
    font-size:var(--p-font);
    font-weight: 500;
    letter-spacing: 0;
    line-height: 27.2px;
    position: relative;
} */

.personalized-content .div-wrapper {
    /* background-image: url(./background-2.svg); */
    background-size: 100% 100%;
    box-shadow: var(--shadow-1);
    height: 32px;
    position: relative;
    width: 32px;
}

.personalized-content .capa {
    height: 357px;
    position: relative;
    width: 357px;
}
