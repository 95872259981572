.img-icon{
    width:20px;
    height:20px;
}


th,td{
    font-size:1.3rem;
    padding:10px 20px;
}


table{
    overflow-x: scroll;
}


.bg-m-grey{
    background-color:rgba(217, 217, 217,0.6)
}

.tr-col{
    background-color:rgba(225, 255, 255,0.7);
}

.cursor-pointer{
    cursor: pointer;
}

.max-h-250{
    max-height:250px !important;
}

.h-fc{
    height: fit-content !important;
}


.w-30{
    width:30% !important;
}

.h-200px{
    height:200px !important;
}

.w-200px{
    width:200px !important;
}


.table-bordered{
    border-collapse: separate !important;
    border-radius: 15px !important;
    /* background-color:white !important; */
    border-spacing: 15px 15px !important;
}


.table{
    /* background-color:transparent !important;
    border-collapse: collapse !important; */
    /* border-spacing: 0 !important;
    border-collapse: separate !important;
    border-radius: 1px !important; */
}

th{
    background-color:white !important;
    border:0 !important;
    margin-bottom: 20px !important;
    text-align: center !important;
    padding:20px !important;
    margin: 0;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 1.1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    font-weight: 600;
}


.table{
    border-collapse: separate !important;
    /* --bs-table-bg:#ECE7E7 !important; */
    --bs-table-bg:rgba(255,255,255,0.6) !important;
    --bs-table-border-color:transparent !important;
    text-align: center !important;
    border-spacing: 0 15px !important; 
    table-layout: auto !important;
    width:2000px !important;
}

td{
    padding:13px !important;
    margin: 0;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 1.1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
}

table tr{
    border-radius: 100px !important;
}

tr td:first-child { 
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
 }

 table tbody tr {
    height: 10px !important;
 }

 tr td:last-child { 
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
 }

 tr td{
    width:fit-content !important;
    white-space: wrap !important;
 }

thead tr th:first-child{
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

thead tr th:last-child{
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

th{
    white-space: nowrap !important;
}

/* tr:first-child td:last-child { border-radius: 20px; }
tr:last-child td:first-child { border-radius: 20px; }
tr:last-child td:last-child { border-radius: 20px; } */
/* tr:first-child td { border-top-style: solid; }
tr td:first-child { border-left-style: solid; } */

@media (min-width:800px){
    .card-lg-card{
       height:240px
    }
    .card-lg-container{
       width:80w;
    }
}


@media (min-width:768px){
  .w-md-45{
    width:48% !important;
  }
}


@media (max-width:1750px){

.table{
    border-collapse: separate !important;
    /* --bs-table-bg:#ECE7E7 !important; */
    --bs-table-bg:rgba(255,255,255,0.6) !important;
    --bs-table-border-color:transparent !important;
    text-align: center !important;
    border-spacing: 0 15px !important; 
}

table tr{
    height: 100px !important;
    overflow: auto !important;
}

td{
    padding:13px !important;
    height: 100px !important;
}

table tr{
    border-radius: 100px !important;
    height: 100px !important;
}

tr td:first-child { 
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
 }

 tr td:last-child { 
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
 }

 tr td{
    height:100px !important;
    overflow: auto;
    white-space: wrap !important;
 }

thead tr th:first-child{
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

thead tr th:last-child{
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

th{
    white-space: nowrap !important;
}
}

