.footer {
    align-items: flex-start;
    background-color: #fafbff;
    display: flex;
    flex-direction: column;
    gap: 56px;
    justify-content: center;
    padding: 150px 0px 0px;
    position: relative;
}

.footer p{
    font-size: 1.0rem;
}

.footer .content {
    align-items: flex-start;
    align-self: stretch;
    background-color: var(--collection-1-primary-main);
    display: flex;
    flex: 0 0 auto;
    gap: 32px;
    padding: 56px 150px;
    position: relative;
    width: 100%;
}

.footer .text-wrapper {
    align-self: stretch;
    color: var(--collection-1-neutral-10);
    flex: 1;
    /* font-family: "Inter-Bold", Helvetica; */
    font-family: "Inter", Helvetica;
    font-size: var(--p-medium);
    font-weight: 700;
    letter-spacing: 0;
    line-height: 36px;
    margin-top: -1px;
    position: relative;
}

.footer .div {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 16px;
    justify-content: center;
    position: relative;
}

.footer .text-wrapper-2 {
    align-self: stretch;
    color: var(--collection-1-neutral-10);
    /* font-family: "Inter-Bold", Helvetica; */
    font-family: "Inter", Helvetica;
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: -1px;
    position: relative;
}

.footer .text-wrapper-3 {
    align-self: stretch;
    color: var(--collection-1-neutral-10);
    /* font-family: "Inter-Regular", Helvetica; */
    font-family: "Inter", Helvetica;
    font-size: 0.95rem;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 24px;
    position: relative;
    cursor: pointer;
}

.footer .title {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 4px;
    justify-content: center;
    position: relative;
    width: 100%;
}

.footer .join-our-community {
    align-self: stretch;
    color: var(--collection-1-neutral-10);
    /* font-family: "Inter-Regular", Helvetica; */
    font-family: "Inter", Helvetica;
    font-size: 0.8rem;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 17px;
    position: relative;
}

.footer .input {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 16px;
    position: relative;
    width: 100%;
}

.footer .frame {
    align-items: flex-start;
    background-color: var(--collection-1-neutral-10);
    border-radius: 6px;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 10px;
    justify-content: center;
    overflow: hidden;
    padding: 10px 8px;
    position: relative;
}

.footer .text-wrapper-4 {
    color: var(--collection-1-neutral-70);
    flex: 1;
    /* font-family: "Inter-Regular", Helvetica; */
    font-family: "Inter", Helvetica;
    font-size: var(--footer-div);
    font-weight: 400;
    letter-spacing: 0;
    line-height: 23.8px;
    margin-top: -1px;
    position: relative;
}

.footer .register {
    align-items: center;
    background-color: var(--collection-1-neutral-10);
    border-radius: 6px;
    box-shadow: var(--shadow-1);
    display: inline-flex;
    flex: 0 0 auto;
    overflow: hidden;
    padding: 12px 20px;
    position: relative;
}

.footer .text-wrapper-5 {
    color: var(--collection-1-primary-main);
    /* font-family: "Inter-SemiBold", Helvetica; */
    font-family: "Inter", Helvetica;
    font-size: var(--footer-div);
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
    cursor: pointer;
}

.footer .by-subscribing-you {
    align-self: stretch;
    color: var(--collection-1-neutral-10);
    font-family: "Inter-medium", Helvetica;
    font-size: 0.8rem;
    /* font-style: italic; */
    font-weight: 400;
    letter-spacing: 0;
    line-height: 17px;
    position: relative;
}

.grid-container {
    display: grid !important;
    grid-template-columns: repeat(4, 1fr) !important;
    grid-auto-rows: auto !important; 
    align-items: flex-start;
    /* grid-row-gap: 20px; /* Adjust the gap between items as needed */
    /* grid-column-gap:90px; */ 
  }
  
  @media (max-width: 1100px) {
    .grid-container {
      grid-template-columns: repeat(2, 1fr) !important;
      grid-row-gap: 20px;
      grid-template-columns: 20px;
    }
  }

  @media (max-width: 650px) {
    .grid-container {
      /* grid-template-columns: repeat(1, 1fr) !important;
      grid-row-gap: 40px;
      grid-template-columns: 50px;
      grid-auto-rows:minmax(50px,auto) !important;  */
      display: flex !important;
      flex-direction: column !important;
    }
    .grid-container .text-wrapper{
        height:fit-content !important;
    }
    .footer{
        padding-top:50px !important;
    }
    .footer .div{
        gap:8px !important;
    }
  }

  /* @media (max-width:) */