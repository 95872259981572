.pricing .switch {
    border-radius: 100px;
    box-shadow: var(--shadow-1);
    display: flex;
    flex-direction: column;
    height: 24px;
    justify-content: center;
    overflow: hidden;
    padding: 0px 4px;
    position: relative;
    width: 56px;
}

.pricing .switch .toggle{
    border-radius: 10px;
    height: 20px;
    position: relative;
    width: 20px;
}

.switch.off {
    align-items: flex-start;
    background-color: var(--collection-1-neutral-10);
}

.switch.on {
    align-items: flex-end;
    background-color: var(--collection-1-primary-main);
}

.switch.off .toggle {
    background-color: var(--collection-1-primary-main);
}

.switch.on .toggle {
    background-color: var(--collection-1-neutral-10);
}

