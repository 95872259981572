.selection {
    background-position: 50% 50%;
    background-size: cover;
    box-shadow: var(--shadow-1);
    height: 467px;
    width: 456px;
}

.selection .overlap-group {
    background: linear-gradient(180deg, rgba(10, 10, 10, 0) 0%, rgb(24.89, 24.89, 24.89) 67.18%);
    border-radius: 6px;
    height: 467px;
    position: relative;
}

.selection .new-content-creator {
    color: var(--collection-1-neutral-10);
    /* font-family: "Inter-Bold", Helvetica; */
    font-family: "Inter", Helvetica;
    font-size: 24px;
    font-weight: 700;
    left: 30px;
    letter-spacing: 0;
    line-height: 40.8px;
    position: absolute;
    white-space: nowrap;
}

.selection .here-this-tool {
    color: var(--collection-1-neutral-10);
    /* font-family: "Inter-Regular", Helvetica; */
    font-family: "Inter", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 30px;
    letter-spacing: 0;
    line-height: 27.2px;
    position: absolute;
    width: 396px;
}

.selection .div {
    background: linear-gradient(180deg, rgba(10, 10, 10, 0) 0%, rgb(24.89, 24.89, 24.89) 86.32%);
    border-radius: 6px;
    height: 158px;
    left: 0;
    position: absolute;
    top: 309px;
    width: 456px;
}

.selection .learn-more {
    align-items: center;
    border-radius: 6px;
    display: inline-flex;
    gap: 10px;
    left: 30px;
    overflow: hidden;
    position: absolute;
    top: 112px;
}

.selection .text-wrapper {
    color: var(--collection-1-neutral-10);
    /* font-family: "Inter-Regular", Helvetica; */
    font-family: "Inter", Helvetica;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -0.5px;
    position: relative;
    width: fit-content;
}

.selection .layer {
    height: 16px;
    position: relative;
    width: 16px;
}

.selection .new-content-creator-2 {
    color: var(--collection-1-neutral-10);
    /* font-family: "Inter-Bold", Helvetica; */
    font-family: "Inter", Helvetica;
    font-size: 24px;
    font-weight: 700;
    left: 30px;
    letter-spacing: 0;
    line-height: 40.8px;
    position: absolute;
    top: 54px;
    white-space: nowrap;
}

.selection .right {
    height: 40px;
    left: 386px;
    position: absolute;
    top: 222px;
    width: 40px;
}

.selection .left {
    height: 40px;
    left: 30px;
    position: absolute;
    top: 222px;
    width: 40px;
}

.selection.normal {
    position: relative;
}

.selection.hover.pro-creator {
    background-image: url(../../../assets/image@2x.png);
}

.selection.hover.new-creator {
    background-image: url(../../../assets/image@2x.png);
}

.selection.new-creator.normal {
    background-image: url(../../../assets/image@2x.png);
}

.selection.pro-creator.normal {
    background-image: url(../../../assets/image@2x.png);
}

.selection.pro-creator .new-content-creator {
    top: 15px;
}

.selection.new-creator .new-content-creator {
    top: 161px;
}

.selection.pro-creator .here-this-tool {
    top: 72px;
}

.selection.new-creator .here-this-tool {
    top: 218px;
}
