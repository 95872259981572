@media (max-width:500px){
    .request-blog-container{
        width:100%
    }
}

@media (min-width:500px){
    .request-blog-container{
        min-width:480px;
        margin:auto
    }
}