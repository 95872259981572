.w-60px{
    width:60px;
    height:60px;
}


.w-15px{
    width:15px;
    height:15px;
}

.z-9999{
    z-index: 9999;
}

.menu-button:hover{
    border:var(--collection-1-primary-main) 2px solid !important;
    transition:2s ease-in-out linear !important;
}