.personalized-content {
    align-items: flex-start;
    background-color: #fafbff;
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: center;
    padding: 100px 150px 0px;
    position: relative;
    overflow:hidden;
}

.personalized-content .title {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
    width: 100%;
}

.personalized-content .text-wrapper {
    align-self: stretch;
    color: var(--collection-1-success-main);
    font-family: "Inter-Medium", Helvetica;
    /* font-size: 20px; */
    font-size: var(--h-tag-font);
    font-weight: 500;
    letter-spacing: 0;
    line-height: 27.2px;
    margin-top: -1px;
    position: relative;
}

.personalized-content .div {
    align-self: stretch;
    color: var(--collection-1-neutral-100);
    font-family: "Inter", Helvetica;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 48px;
    position: relative;
}

/* .personalized-content .p {
    align-self: stretch;
    color: var(--collection-1-neutral-90);
    font-family: "Inter-Regular", Helvetica;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 27.2px;
    position: relative;
} */

.personalized-content .content {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 32px;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.personalized-content .div-2 {
    align-items: flex-start;
    align-self: stretch;
    background-color: var(--collection-1-neutral-10);
    border: 1px solid;
    border-color: var(--collection-1-neutral-50);
    border-radius: 6px;
    box-shadow: var(--shadow-1);
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 20px;
    justify-content: center;
    overflow: hidden;
    padding: 20px;
    position: relative;
}

.personalized-content .img {
    flex: 0 0 auto;
    position: relative;
}

.personalized-content .content-2 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 100%;
}

.personalized-content .text-wrapper-2 {
    align-self: stretch;
    color: var(--collection-1-primary-hover);
    font-family: "Inter", Helvetica;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 34px;
    margin-top: -1px;
    position: relative;
}

.personalized-content .data-driven {
    align-items: flex-start;
    background-color: var(--collection-1-neutral-10);
    border: 1px solid;
    border-color: var(--collection-1-neutral-50);
    border-radius: 6px;
    box-shadow: var(--shadow-1);
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 20px;
    justify-content: center;
    overflow: hidden;
    padding: 20px;
    position: relative;
}

.colored{
    color:var(--collection-1-primary-main) !important;
}


.personalized-content .text-wrapper-3 {
    align-self: stretch;
    color: var(--collection-1-primary-hover);
    font-family: "Inter", Helvetica;
    font-size:var(--p-light);
    /* font-size: 20px; */
    font-weight: 600;
    letter-spacing: 0;
    line-height: 34px;
    margin-top: -1px;
    position: relative;
}

.personalized-content .p {
    align-self: stretch;
    color: var(--collection-1-neutral-90);
    font-family: "Inter", Helvetica;
    /* font-size: 16px; */
    font-size:var(--p-light);
    font-weight: 400;
    letter-spacing: 0;
    line-height: 27.2px;
    position: relative;
}

.feature-movement {
    /* right: 0; */
    /* position: relative;
    animation: feature 1s ease-in-out; */
}

#feature-movement1 {
    position: relative;
    /* right: -1000px; */
}

#feature-movement2 {
    position: relative;
    /* right: -1500px; */
}

#feature-movement3 {
    position: relative;
    /* right: -2000px; */
}

@keyframes feature {
    0% {
        right: -1000px; /* Start from -5000px */
    }
    100% {
        right: 0; /* Move to 0px */
    }
}
