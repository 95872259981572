.pricing {
    align-items: flex-start;
    background-color: var(--collection-1-neutral-10);
    display: flex;
    flex-direction: column;
    gap: 56px;
    justify-content: center;
    /* padding: 100px 0px 0px; */
    position: relative;
    z-index: 500;
}

.pricing .content {
    align-items: flex-start;
    align-self: stretch;
    /* background-color: #f3f7ff; */
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 32px;
    justify-content: center;
    padding: 56px 150px;
    position: relative;
    width: 100%;
}

.pricing .title {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 8px;
    position: relative;
    width: 100%;
}

.pricing .text-wrapper {
    align-self: stretch;
    color: var(--collection-1-neutral-100);
    /* font-family: "Inter-Bold", Helvetica; */
    font-family: "Inter", Helvetica;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 48px;
    margin-top: -1px;
    position: relative;
    text-align: center;
}

.pricing .we-provide-some-of {
    align-self: stretch;
    color: var(--collection-1-neutral-90);
    /* font-family: "Inter-Regular", Helvetica; */
    font-family: "Inter", Helvetica;
    font-size:var(--p-font);
    font-weight: 400;
    letter-spacing: 0;
    line-height: 30.6px;
    position: relative;
    text-align: center;
}

.pricing .payment-type {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 32px;
    justify-content: center;
    position: relative;
    width: 100%;
}

.pricing .div {
    color: var(--collection-1-neutral-90);
    /* font-family: "Inter-Regular", Helvetica; */
    font-family: "Inter", Helvetica;
    /* font-size: 16px; */
    font-size:var(--p-light);
    font-weight: 400;
    letter-spacing: 0;
    line-height: 27.2px;
    margin-top: -1px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
}

.pricing .plans {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 32px;
    position: relative;
    width: 100%;
}

/* .pricing .div-2 {
    align-items: flex-start;
    align-self: stretch;
    background-color: var(--collection-1-neutral-10);
    border-radius: 16px;
    box-shadow: var(--shadow-1);
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 32px;
    overflow: hidden;
    padding: 30px;
    position: relative;
} */


.pricing .div-2{
    background-color: var(--collection-1-neutral-10);
    border-radius: 16px;
    box-shadow: var(--shadow-1);
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 32px;
    overflow: none;
    padding: 30px;
    position: relative;
}

.pricing .div-3 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: var(--p-font);
    justify-content: center;
    position: relative;
    width: 100%;
}

.pricing .div-4 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 4px;
    justify-content: center;
    position: relative;
    width: 100%;
}

.pricing .text-wrapper-2 {
    align-self: stretch;
    color: var(--collection-1-neutral-100);
    /* font-family: "Inter-Bold", Helvetica; */
    font-family: "Inter", Helvetica;
    font-size: var(--p-light);
    /* font-size: 18px; */
    font-weight: 700;
    letter-spacing: -0.18px;
    line-height: 27.2px;
    margin-top: -1px;
    position: relative;
}

.pricing .p {
    align-self: stretch;
    color: var(--collection-1-neutral-90);
    /* font-family: "Inter-Regular", Helvetica; */
    font-family: "Inter", Helvetica;
    /* font-size: 0.95rem; */
    font-size:var(--p-medium);
    font-weight: 400;
    letter-spacing: -0.15px;
    line-height: 23.8px;
    position: relative;
}

.pricing .text-wrapper-3 {
    align-self: stretch;
    color: var(--collection-1-neutral-100);
    /* font-family: "Inter-Bold", Helvetica; */
    font-family: "Inter", Helvetica;
    /* font-size: 26px; */
    font-size: var(--h-font);
    font-weight: 700;
    letter-spacing: -0.29px;
    line-height: 39px;
    margin-top: -1px;
    position: relative;
}

.pricing .text-wrapper-4 {
    align-self: stretch;
    color: var(--collection-1-primary-main);
    font-family: "Inter", Helvetica;
    /* font-size: 1rem; */
    font-size:var(--p-light);
    /* font-style: italic; */
    font-weight: 600;
    letter-spacing: -0.18px;
    line-height: 24px;
    position: relative;
    text-align:center;
    padding:10px;
}

.pricing .benefit {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 12px;
    position: relative;
    width: 100%;
}

.pricing .check {
    height: 24px;
    position: relative;
    width: 24px;
}

.pricing .text-wrapper-5 {
    color: var(--collection-1-neutral-90);
    flex: 1;
    font-family: "Inter", Helvetica;
    /* font-size: 0.95rem; */
    font-size:var(--p-medium);
    font-weight: 400;
    letter-spacing: -0.15px;
    line-height: 23.8px;
    margin-top: -1px;
    position: relative;
    margin:0;
    gap:2rem;
}

.pricing .register {
    align-items: center;
    align-self: stretch;
    background-color: var(--collection-1-primary-main);
    border-radius: 6px;
    box-shadow: var(--shadow-1);
    display: flex;
    flex: 0 0 auto;
    justify-content: center;
    overflow: hidden;
    padding: 12px 20px;
    position: relative;
    width: 100%;
}

.pricing .choose-this-plan {
    color: var(--collection-1-neutral-10);
    flex: 1;
    font-family: "Inter", Helvetica;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-align: center;
    width:100%;
}

.pricing .choose-this-plan-wrapper {
    align-items: center;
    align-self: stretch;
    background-color: var(--collection-1-neutral-10);
    border: 1px solid;
    border-color: var(--collection-1-primary-main);
    border-radius: 6px;
    display: flex;
    flex: 0 0 auto;
    justify-content: center;
    overflow: hidden;
    padding: 12px 20px;
    position: relative;
    width: 100%;
}

.pricing .choose-this-plan-2 {
    color: var(--collection-1-primary-main);
    flex: 1;
    font-family: "Inter", Helvetica;
    font-size: 0.95rem;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-align: center;
}


.flip-container {
    /* perspective: 1000px; */
  }
  
  .flipper {
    transform-style: preserve-3d;
    transition: transform 0.6s;
  }
  
  .flipped .flipper {
    transform: rotateY(180deg);
  }
  
  .front{
    width: 100%;
    height: 100%;
    position: absolute;
    backface-visibility: hidden;
  }

  .back {
    width: 100%;
    height: 100%;
    position: relative;
    backface-visibility: hidden;
  }
  
  .front {
    z-index: 2;
    /* Styles for the front side of the card */
  }
  
  .back {
    transform: rotateY(180deg);
    /* Styles for the back side of the card */
  }
  
  /* Additional styles for clarity */
  .front, .back {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
 
#pricing .register:hover{
    align-items: center;
    align-self: stretch;
    background-color: var(--collection-1-neutral-10);
    border: 2px solid;
    border-color: var(--collection-1-primary-main);
    border-radius: 6px;
    display: flex;
    flex: 0 0 auto;
    justify-content: center;
    overflow: hidden;
    padding: 12px 20px;
    position: relative;
    width: 100%;
}


#pricing .choose-this-plan:hover {
    color:var(--collection-1-primary-main) !important;
}
