.instruction {
    align-items: center;
    background-color: #fafbff;
    display: flex;
    gap: 56px;
    padding: 100px 150px 0px;
    position: relative;
}

.instruction .content {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 24px;
    justify-content: center;
    position: relative;
}

.instruction .title {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 14px;
    justify-content: center;
    position: relative;
    width: 100%;
}

.instruction .div {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
    width: 100%;
}

.instruction .text-wrapper {
    align-self: stretch;
    color: var(--collection-1-success-main);
    /* font-family: "Inter-Medium", Helvetica; */
    font-family: "Inter", Helvetica;
    /* font-size: 20px; */
    font-size:var(--h-tag-font);
    font-weight: 500;
    letter-spacing: 0;
    line-height: 27.2px;
    margin-top: -1px;
    position: relative;
}

.instruction .features-that-make {
    align-self: stretch;
    color: var(--collection-1-neutral-100);
    /* font-family: "Inter-Bold", Helvetica; */
    font-family: "Inter", Helvetica;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 48px;
    position: relative;
}

.instruction .span {
    color: #0a0a0a;
}

.instruction .text-wrapper-2 {
    color: #3870ff;
}

.instruction .p {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    align-self: stretch;
    color: var(--collection-1-neutral-90);
    display: -webkit-box;
    /* font-family: "Inter-Regular", Helvetica; */
    font-family: "Inter", Helvetica;
    /* font-size: 18px; */
    font-size: var(--p-font);
    font-weight: 400;
    height: 96px;
    letter-spacing: 0;
    line-height: 30.6px;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
}

.instruction .login {
    align-items: center;
    background-color: var(--collection-1-neutral-10);
    border: 1px solid;
    border-color: var(--collection-1-neutral-50);
    border-radius: 6px;
    display: inline-flex;
    flex: 0 0 auto;
    overflow: hidden;
    padding: 12px 20px;
    position: relative;
}

.instruction .text-wrapper-3 {
    color: var(--collection-1-neutral-100);
    /* font-family: "Inter-Regular", Helvetica; */
    font-family: "Inter", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.instruction .ilustration {
    height: 284px;
    position: relative;
    width: 435px;
}

.instruction .overlap {
    height: 284px;
    position: relative;
    width: 445px;
}

.instruction .rectangle {
    background-color: var(--collection-1-primary-main);
    border-radius: 16px;
    height: 272px;
    left: 58px;
    position: absolute;
    top: 0;
    transform: rotate(180deg);
    width: 321px;
}

.instruction .group {
    height: 196px;
    left: 78px;
    position: absolute;
    top: 20px;
    width: 367px;
}

.instruction .overlap-group {
    border-radius: 16px;
    height: 196px;
    position: relative;
    width: 357px;
}

.instruction .rectangle-2 {
    background-color: var(--collection-1-neutral-10);
    border-radius: 16px;
    height: 196px;
    left: 0;
    position: absolute;
    top: 0;
    transform: rotate(180deg);
    width: 357px;
}

.instruction .traffic-content {
    color: var(--collection-1-neutral-100);
    /* font-family: "Inter-SemiBold", Helvetica; */
    font-family: "Inter", Helvetica;
    font-size: 12px;
    font-weight: 600;
    left: 16px;
    letter-spacing: 0;
    line-height: 20.4px;
    position: absolute;
    top: 14px;
    white-space: nowrap;
}

.instruction .element {
    color: var(--collection-1-neutral-90);
    /* font-family: "Inter-Regular", Helvetica; */
    font-family: "Inter", Helvetica;
    font-size: 12px;
    font-weight: 400;
    left: 16px;
    letter-spacing: 0;
    line-height: 20.4px;
    position: absolute;
    top: 50px;
    white-space: nowrap;
}

.instruction .element-k {
    color: var(--collection-1-neutral-90);
    /* font-family: "Inter-Regular", Helvetica; */
    font-family: "Inter", Helvetica;
    font-size: 12px;
    font-weight: 400;
    left: 16px;
    letter-spacing: 0;
    line-height: 20.4px;
    position: absolute;
    top: 86px;
    white-space: nowrap;
}

.instruction .element-2 {
    color: var(--collection-1-neutral-90);
    /* font-family: "Inter-Regular", Helvetica; */
    font-family: "Inter", Helvetica;
    font-size: 12px;
    font-weight: 400;
    left: 16px;
    letter-spacing: 0;
    line-height: 20.4px;
    position: absolute;
    top: 122px;
    white-space: nowrap;
}

.instruction .element-3 {
    color: var(--collection-1-neutral-90);
    /* font-family: "Inter-Regular", Helvetica; */
    font-family: "Inter", Helvetica;
    font-size: 12px;
    font-weight: 400;
    left: 16px;
    letter-spacing: 0;
    line-height: 20.4px;
    position: absolute;
    top: 158px;
    white-space: nowrap;
}

.instruction .monthly {
    align-items: center;
    display: inline-flex;
    gap: 4px;
    left: 281px;
    position: absolute;
    top: 14px;
}

.instruction .text-wrapper-4 {
    color: var(--collection-1-neutral-70);
    /* font-family: "Inter-Regular", Helvetica; */
    font-family: "Inter", Helvetica;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 20.4px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.instruction .down {
    height: 10px;
    position: relative;
    width: 10px;
}

.instruction .line {
    height: 1px;
    left: 16px;
    object-fit: cover;
    position: absolute;
    top: 181px;
    width: 325px;
}

.instruction .img {
    height: 1px;
    left: 53px;
    position: absolute;
    top: 167px;
    width: 288px;
}

.instruction .line-2 {
    height: 1px;
    left: 53px;
    position: absolute;
    top: 131px;
    width: 288px;
}

.instruction .line-3 {
    height: 1px;
    left: 53px;
    position: absolute;
    top: 95px;
    width: 288px;
}

.instruction .line-4 {
    height: 1px;
    left: 53px;
    position: absolute;
    top: 59px;
    width: 288px;
}

.instruction .rectangle-3 {
    background-color: var(--collection-1-primary-main);
    border-radius: 2px 2px 0px 0px;
    height: 31px;
    left: 51px;
    position: absolute;
    top: 151px;
    width: 9px;
}

.instruction .rectangle-4 {
    background-color: var(--collection-1-primary-main);
    border-radius: 2px 2px 0px 0px;
    height: 40px;
    left: 87px;
    position: absolute;
    top: 142px;
    width: 9px;
}

.instruction .rectangle-5 {
    background-color: var(--collection-1-primary-main);
    border-radius: 2px 2px 0px 0px;
    height: 50px;
    left: 123px;
    position: absolute;
    top: 132px;
    width: 9px;
}

.instruction .rectangle-6 {
    background-color: var(--collection-1-primary-main);
    border-radius: 2px 2px 0px 0px;
    height: 56px;
    left: 159px;
    position: absolute;
    top: 126px;
    width: 9px;
}

.instruction .rectangle-7 {
    background-color: var(--collection-1-primary-main);
    border-radius: 2px 2px 0px 0px;
    height: 66px;
    left: 195px;
    position: absolute;
    top: 116px;
    width: 9px;
}

.instruction .rectangle-8 {
    background-color: var(--collection-1-primary-main);
    border-radius: 2px 2px 0px 0px;
    height: 76px;
    left: 231px;
    position: absolute;
    top: 106px;
    width: 9px;
}

.instruction .rectangle-9 {
    background-color: var(--collection-1-primary-main);
    border-radius: 2px 2px 0px 0px;
    height: 96px;
    left: 267px;
    position: absolute;
    top: 86px;
    width: 9px;
}

.instruction .rectangle-10 {
    background-color: var(--collection-1-primary-main);
    border-radius: 2px 2px 0px 0px;
    height: 122px;
    left: 303px;
    position: absolute;
    top: 60px;
    width: 9px;
}

.instruction .frame {
    align-items: flex-start;
    background-color: var(--collection-1-neutral-10);
    border-radius: 16px;
    box-shadow: var(--shadow-1);
    display: flex;
    flex-direction: column;
    gap: 4px;
    left: 0;
    padding: 10px;
    position: absolute;
    top: 216px;
    width: 241px;
}

.instruction .content-progress {
    align-self: stretch;
    color: var(--collection-1-neutral-90);
    /* font-family: "Inter-Medium", Helvetica; */
    font-family: "Inter", Helvetica;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20.4px;
    margin-top: -1px;
    position: relative;
}

.instruction .frame-2 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 4px;
    position: relative;
    width: 100%;
}

.instruction .text-wrapper-5 {
    color: var(--collection-1-neutral-100);
    flex: 1;
    /* font-family: "Inter-SemiBold", Helvetica; */
    font-family: "Inter", Helvetica;
    /* font-size: 14px; */
    font-size:var(--p-light);
    font-weight: 700;
    letter-spacing: 0;
    line-height: 23.8px;
    margin-top: -1px;
    position: relative;
}

.instruction .frame-3 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 4px;
    position: relative;
}

.instruction .capa {
    height: 12px;
    position: relative;
    width: 12px;
}

.instruction .text-wrapper-6 {
    color: var(--collection-1-success-main);
    /* font-family: "Inter-Bold", Helvetica; */
    font-family: "Inter", Helvetica;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 17px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

@media (max-width:600px) {
    .illustation-img{
        width:100%;
        height: auto;
        margin:50px 0px;
    }
    .mobile-rectangle {
        position: relative !important;
        left:0 !important;
        width:100% !important;
    }

    .mobile-illustration {
        width: auto !important;
        display:none !important;
    }

    .mobile-overlap {
        width: auto !important;
    }
    .mobile-group{
        left:0 !important;
        width:auto !important;
    }
    .mobile-rectangle-2{
        width:75% !important;
        margin:auto !important;
    }
    .mobile-frame{
        top:100px !important;
    }
}


.instruction .text-wrapper-8 {
    align-self: stretch;
    color: var(--collection-1-neutral-90);
    /* font-family: "Inter-Regular", Helvetica; */
    font-family: "Inter", Helvetica;
    /* font-size: 16px; */
    font-size:var(--p-tiny);
    font-weight: 700;
    letter-spacing: 0;
    line-height: 27.2px;
    position: relative;
}