.w-35px{
    width:35px !important;
    height:35px !important;
}


.navigation {
    align-items: center;
    display: flex;
    padding: 14px 150px;
    position: relative;
    z-index:1000;
    border-bottom: 3px solid var(--collection-1-primary-main);
}

.navigation .background {
    background-color: #ffffff94;
    filter: blur(10px);
    height: 67px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1440px;
}

.navigation .logo {
    align-items: center;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 8px;
    position: relative;
}

.navigation .img {
    /* height: 39px; */
    position: relative;
    /* width: 39px; */
}

.navigation .title {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 2px;
    position: relative;
}

.navigation .text-wrapper {
    align-self: stretch;
    color: var(--collection-1-neutral-100);
    /* font-family: "Inter-Regular", Helvetica; */
    font-family: "Inter", Helvetica;
    /* font-size: 12px; */
    font-size:var(--p-miny);
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    opacity:0.9;
    
}

.navigation .div {
    align-self: stretch;
    color: var(--collection-1-neutral-90);
    /* font-family: "Inter-Regular", Helvetica; */
    font-family: "Inter", Helvetica;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
}

.navigation .contain {
    align-items: center;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 56px;
    justify-content: flex-end;
    position: relative;
}

.navigation .link {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 40px;
    justify-content: flex-end;
    position: relative;
}

.navigation .text-wrapper-2 {
    color: var(--collection-1-primary-main);
    /* font-family: "Inter-SemiBold", Helvetica; */
    font-family: "Inter", Helvetica;
    font-size:var(--p-light);
    /* font-size: 12px; */
    font-weight: 600;
    letter-spacing:0.2px;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
}

.navigation .text-wrapper-3 {
    color: var(--collection-1-neutral-90);
    /* font-family: "Inter-Regular", Helvetica; */
    font-family: "Inter", Helvetica;
    font-size:var(--p-miny);
    font-weight: 300;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
    opacity:0.8;
}

.navigation .button {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 24px;
    justify-content: flex-end;
    position: relative;
}

.navigation .login {
    align-items: center;
    background-color: var(--collection-1-neutral-10);
    border: 1px solid;
    border-color: var(--collection-1-neutral-50);
    border-radius: 6px;
    display: inline-flex;
    flex: 0 0 auto;
    overflow: hidden;
    padding: 12px 20px;
    position: relative;
}

.navigation .text-wrapper-4 {
    color: var(--collection-1-neutral-100);
    /* font-family: "Inter-Regular", Helvetica; */
    font-family: "Inter", Helvetica;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
}

.navigation .register {
    align-items: center;
    background-color: var(--collection-1-primary-main);
    border-radius: 6px;
    box-shadow: var(--shadow-1);
    display: inline-flex;
    flex: 0 0 auto;
    overflow: hidden;
    padding: 12px 20px;
    position: relative;
}

.navigation .text-wrapper-5 {
    color: var(--collection-1-neutral-10);
    /* font-family: "Inter-SemiBold", Helvetica; */
    font-family: "Inter", Helvetica;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
}

.login {
    align-items: center;
    background-color: var(--collection-1-neutral-10);
    border: 1px solid;
    border-color: var(--collection-1-neutral-50);
    border-radius: 6px;
    display: inline-flex;
    overflow: hidden;
    padding: 12px 20px;
    position: relative;
}

.login .text-wrapper {
    color: var(--collection-1-neutral-100);
    /* font-family: "Inter-Regular", Helvetica; */
    font-family: "Inter", Helvetica;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
}

.register {
    align-items: center;
    background-color: var(--collection-1-primary-main);
    border-radius: 6px;
    box-shadow: var(--shadow-1);
    display: inline-flex;
    overflow: hidden;
    padding: 12px 20px;
    position: relative;
}

.register .text-wrapper {
    color: var(--collection-1-neutral-10);
    /* font-family: "Inter-Regular", Helvetica; */
    font-family: "Inter", Helvetica;
    font-size:var(--h-header-font);
    /* font-size: 12px; */
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
}


/* .menu-icon-dash:hover{
     border:2px solid var(--collection-1-primary-main);
     padding:5px;
} */

.navigation a:hover{
    text-decoration: none !important;
} 

/* .navigation a.active{
    color:var(--collection-1-primary-main) !important;
}  */
/* 
.responsive-nav{
    display: none;
}

.responsive-nav {
    display: none;
    opacity: 0;
    transition: opacity 2s ease-in-out;
}

.responsive-nav.active {
    display: block;
    opacity: 1;
} */

.responsive-nav {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

/* .responsive-nav.active {
    max-height: 1000px;
    opacity: 1;
    z-index: 1000;
    display: block;
    position:absolute;
} */

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Dark overlay color with 0.5 opacity */
    z-index: 999; /* Ensure the overlay is on top */
    display: none; /* Initially hidden */
  }
  
  .responsive-nav.active {
    max-height: 100vh; /* Set max height to the full viewport height */
    opacity: 1;
    z-index: 1000;
    display: block;
    position: absolute;
    width: 100%;
  }
  
  
  /* Style for the close button inside the navbar */
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }


/* .responsive-nav a{
    color:black !important;
} */

/* .responsive-nav a.active{
    color:var(--collection-1-primary-main) !important;
} */

.menu-icon-dash {
    width: 30px;
    height: 20px;
    position: relative;
    cursor: pointer;
  }
  
  .line {
    width: 100%;
    height: 3px;
    background-color: #000; /* You can set the color as needed */
    margin: 5px 0;
  }
  

  /* Assuming you have a class "my-component" wrapping the content */
.my-component {
    position: relative;
  }
  
  /* Adjust the position of the Widget to the left */
  .rcw-widget-container {
    position: fixed;
    left: 20px; /* Adjust the left distance as needed */
    bottom: 20px; /* Adjust the bottom distance as needed */ /* Adjust the z-index if necessary to make it appear above other elements */
  }
  

  .contain a:hover{
    transform: scale(1.1);
  }