@media (max-width:900px){
    .flat-blog-card{
        max-width:100% ;
    }
}

@media (min-width:900px){
  .flat-blog-card{
         max-width:400px
  }
}