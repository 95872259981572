.questionnaire-page-container{
    flex:1;
}

.domains-typography{
    color:var(--collection-1-primary-main);
    font-weight:700;
    text-decoration: dashed;
}


.domains-typography:hover{
    color:var(--collection-1-primary-main);
}

.feedback-container{
    min-width:100px;
    max-width:300px;
    bottom:100px;
    padding:30px;
    left:50px;
    /* transform:2s ease-in-out; */
    animation:moving-feedback 0.5s linear;
    z-index: 99;
}

@keyframes moving-feedback{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}