.header {
    align-items: center;
    background-color: #f9fbff;
    display: flex;
    gap: 120px;
    height: 500px;
    justify-content: center;
    overflow: hidden;
    padding: 56px 200px 100px;
    position: relative;
}

.header .background {
    height: 1332px;
    left: 32px;
    position: absolute;
    top: -416px;
    width: 1511px;
}

.header .overlap {
    height: 1332px;
    position: relative;
}

.header .circle {
    height: 1332px;
    left: 22px;
    position: absolute;
    top: 0;
    width: 1332px;
}

.header .overlap-group {
    border-radius: 666px;
    height: 1332px;
    position: relative;
}

.header .ellipse {
    border: 2px solid;
    border-color: #e6e9ed;
    border-radius: 195px;
    height: 390px;
    left: 471px;
    position: absolute;
    top: 471px;
    width: 390px;
}

.header .div {
    border: 2px solid;
    border-color: #e6e9ed;
    border-radius: 349px;
    height: 698px;
    left: 317px;
    position: absolute;
    top: 317px;
    width: 698px;
}

.header .ellipse-2 {
    border: 2px solid;
    border-color: #e6e9ed;
    border-radius: 456px;
    height: 912px;
    left: 210px;
    position: absolute;
    top: 210px;
    width: 912px;
}

.header .ellipse-3 {
    border: 2px solid;
    border-color: #e6e9ed;
    border-radius: 572px;
    height: 1144px;
    left: 94px;
    position: absolute;
    top: 94px;
    width: 1144px;
}

.header .ellipse-4 {
    border: 2px solid;
    border-color: #e6e9ed;
    border-radius: 666px;
    height: 1332px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1332px;
}

.header .background-2 {
    background-color: #ffb74c14;
    border-radius: 327px/253.5px;
    filter: blur(100px);
    height: 507px;
    left: 565px;
    position: absolute;
    top: 397px;
    width: 654px;
}

.header .background-3 {
    background-color: #3870ff14;
    border-radius: 265.5px/314.5px;
    filter: blur(100px);
    height: 629px;
    left: 980px;
    position: absolute;
    top: 337px;
    width: 531px;
}

.header .users {
    height: 387px;
    left: 0;
    position: absolute;
    top: 480px;
    width: 1354px;
}

.header .image {
    height: 44px;
    left: 352px;
    position: absolute;
    top: 321px;
    width: 44px;
}

.header .img {
    height: 44px;
    left: 210px;
    position: absolute;
    top: 183px;
    width: 44px;
}

.header .image-2 {
    height: 44px;
    left: 1106px;
    position: absolute;
    top: 0;
    width: 44px;
}

.header .omage {
    height: 44px;
    left: 1246px;
    position: absolute;
    top: 171px;
    width: 44px;
}

.header .image-3 {
    height: 44px;
    left: 0;
    position: absolute;
    top: 86px;
    width: 44px;
}

.header .image-4 {
    height: 44px;
    left: 1310px;
    position: absolute;
    top: 343px;
    width: 44px;
}

.header .youtube {
    height: 44px;
    left: 972px;
    position: absolute;
    top: 831px;
    width: 44px;
}

.header .like {
    height: 44px;
    left: 124px;
    position: absolute;
    top: 449px;
    width: 44px;
}

.header .class {
    align-items: center;
    background-color: transparent;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 24px;
    position: relative;
}

.header .title {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 8px;
    position: relative;
    width: 100%;
}

.header .elevate-your-youtube {
    align-self: stretch;
    color: var(--collection-1-neutral-100);
    font-family: "Inter", Helvetica;
    /* font-size: 40px; */
    font-size:2.5rem;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 60px;
    margin-top: -1px;
    position: relative;
    text-align: center;
}

.header .text-wrapper {
    color: #0a0a0a;
}

.header .span {
    color: var(--collection-1-primary-main);
}

.header .p {
    align-self: stretch;
    color: var(--collection-1-neutral-90);
    /* font-family: "Inter-Regular", Helvetica; */
     font-family: "Inter", Helvetica;
    /* font-size: 24px; */
    font-size:1.5rem;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 40.8px;
    position: relative;
    text-align: center;
}

.header .button {
    all: unset;
    align-items: center;
    background-color: var(--collection-1-primary-main);
    border-radius: 6px;
    box-shadow: var(--shadow-1);
    box-sizing: border-box;
    display: inline-flex;
    flex: 0 0 auto;
    overflow: hidden;
    padding: 12px 20px;
    position: relative;
}

.header .text-wrapper-2 {
    color: var(--collection-1-neutral-10);
    /* font-family: "Inter-SemiBold", Helvetica; */
    font-family: "Inter", Helvetica;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
}

.header {
    align-items: center;
    background-color: #f9fbff;
    display: flex;
    gap: 120px;
    height: 500px;
    justify-content: center;
    overflow: hidden;
    padding: 56px 200px 100px;
    position: relative;
}

.header .background {
    background-image: url("../../../assets/background@2x.webp");
    background-size: cover;
    background-position: center; /* Center the background image */
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: scale(0.5); /* Adjust the scaling as needed */
}

@media (max-width:600px){
    .header{
        padding:0 !important;
        height:800px !important;
    }
}

@media (min-width:600px){
    .header{
        padding:0 !important;
        height:600px !important;
        background-size: cover !important;
        background-position: center center !important;
        position: relative !important;
    }
}

.custom-hover:hover{
    transform: rotate(6deg); 
    transition: transform 0.3s ease;
}
/* 
.custom-toggle{
    transform: rotate(10deg); 
    transition: transform 0.3s ease;   
} */


@media (max-width:700px){
    .header{
       background-position: center; 
    }    
    #home-header{
        background-image:url("../../../assets/background@2x.png") !important;
    }
}

@media (max-width:600px){
    .header{
       background-position: center; 
    }    
    #home-header{
        background-image:url("../../../assets/Background_mobile.webp") !important;
    }
    .elevate-your-youtube{
        line-height: 3rem !important;
    }
    .typewriting-p{
        line-height: 1.7rem !important;
    }
}

#home-header{
    background-image:url("../../../assets/background@2x.png");
} 