.login-container1 {
    align-items: center;
    background-color: var(--collection-1-neutral-10);
    border-radius: 16px;
    box-shadow: var(--shadow-1);
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: center;
    overflow: hidden;
    padding: 32px;
    position: relative;
    /* width: 422px !important; */
}

.login .title {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 4px;
    justify-content: center;
    position: relative;
    width: 100%;
}

.login-container1 .text-wrapper {
    /* align-self: stretch; */
    color: var(--collection-1-neutral-100);
    /* font-family: "Inter-Bold", Helvetica; */
    font-family: "Inter", Helvetica;
    font-size: 22px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 34px;
    margin-top: -1px;
    position: relative;
    text-align: center;
}

.login .let-s-build-your {
    align-self: stretch;
    color: var(--collection-1-neutral-90);
    /* font-family: "Inter-Regular", Helvetica; */
    font-family: "Inter", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 27.2px;
    position: relative;
    text-align: center;
}

.login-container1 .input {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    position: relative;
    width: 100%;
}

.login-container1 .div {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    position: relative;
    width: 100%;
}

.login-container1 .email {
    align-self: stretch;
    background: transparent;
    border: none;
    color: var(--collection-1-neutral-100);
    /* font-family: "Inter-Medium", Helvetica; */
    font-family: "Inter", Helvetica;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    padding: 0;
    position: relative;
}

.login-container1 .input-2 {
    align-self: stretch;
    background-color: var(--collection-1-neutral-10);
    border: 1px solid;
    border-color: var(--collection-1-neutral-50);
    border-radius: 6px;
    color: var(--collection-1-neutral-100);
    flex: 1;
    /* font-family: "Inter-Regular", Helvetica; */
    font-family: "Inter", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    overflow: hidden;
    padding: 10px;
    position: relative;
    width: 100%;
}

.login-container1 .text-wrapper-2 {
    align-self: stretch;
    color: var(--collection-1-neutral-100);
    /* font-family: "Inter-Medium", Helvetica; */
    font-family: "Inter", Helvetica;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
}

.login-container1 .input-3 {
    align-items: center;
    align-self: stretch;
    background-color: var(--collection-1-neutral-10);
    border: 1px solid;
    border-color: var(--collection-1-neutral-50);
    border-radius: 6px;
    display: flex;
    flex: 0 0 auto;
    justify-content: center;
    overflow: hidden;
    padding: 10px;
    position: relative;
    width: 100%;
}

.login-container1 .text-wrapper-3 {
    color: var(--collection-1-neutral-100);
    flex: 1;
    /* font-family: "Inter-Regular", Helvetica; */
    font-family: "Inter", Helvetica;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -0.5px;
    position: relative;
}

.login-container1 .img {
    height: 16px;
    position: relative;
    width: 16px;
}

.login-container1 .text-wrapper-4 {
    align-self: stretch;
    color: var(--collection-1-info-main);
    /* font-family: "Inter-SemiBold", Helvetica; */
    font-family: "Inter", Helvetica;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    text-align: right;
}

.login-container1 .register {
    align-items: center;
    align-self: stretch;
    background-color: var(--collection-1-primary-main);
    border-radius: 6px;
    box-shadow: var(--shadow-1);
    display: flex;
    flex: 0 0 auto;
    justify-content: center;
    overflow: hidden;
    padding: 12px 20px;
    position: relative;
    width: 100%;
}

.login-container1 .text-wrapper-5 {
    color: var(--collection-1-neutral-10);
    flex: 1;
    /* font-family: "Inter-SemiBold", Helvetica; */
    font-family: "Inter", Helvetica;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-align: center;
}

.login-container1 .register-2 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 16px;
    justify-content: center;
    position: relative;
    width: 100%;
}

.login-container1 .line {
    flex: 1;
    flex-grow: 1;
    height: 1px;
    object-fit: cover;
    position: relative;
}

.login-container1 .text-wrapper-6 {
    color: var(--collection-1-neutral-90);
    /* font-family: "Inter-Regular", Helvetica; */
    font-family: "Inter", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-align: center;
    width: fit-content;
}

.login-container1 .button-container {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 32px;
    justify-content: center;
    position: relative;
    width: 100%;
}

.login-container1 .google {
    align-items: center;
    border: 1px solid;
    border-color: var(--collection-1-neutral-50);
    border-radius: 6px;
    box-shadow: var(--shadow-1);
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 6px;
    justify-content: center;
    overflow: hidden;
    padding: 12px 20px;
    position: relative;
}

.login-container1 .text-wrapper-7 {
    color: var(--collection-1-neutral-90);
    /* font-family: "Inter-SemiBold", Helvetica; */
    font-family: "Inter", Helvetica;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -0.5px;
    position: relative;
    text-align: center;
    width: fit-content;
}

.login-container1 .don-t-have-an {
    color: var(--collection-1-neutral-90);
    /* font-family: "Inter-Regular", Helvetica; */
    font-family: "Inter", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    text-align: center;
    width: fit-content;
}

.login-container1 .span {
    color: #404040;
}

.login-container1 .text-wrapper-8 {
    color: #3870ff;
    /* font-family: "Inter-SemiBold", Helvetica; */
    font-family: "Inter", Helvetica;
    font-weight: 600;
}

/* styles.css */

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Adjust the z-index value as needed */
  }
  
  .login-container1 {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    z-index: 1001; /* Make sure this value is higher than the z-index of other elements */
  }
  
  .google {
    align-items: center;
    /* border: 1px solid; */
    border-color: var(--collection-1-neutral-50);
    border-radius: 6px;
    box-shadow: var(--shadow-1);
    display: flex;
    gap: 6px;
    justify-content: center;
    overflow: hidden;
    padding: 12px 20px;
    position: relative;
    }
    .google .img {
    height: 16px;
    position: relative;
    width: 16px;
    }
    .google .text-wrapper {
    color: var(--collection-1-neutral-90);
    /* font-family: "Inter-SemiBold", Helvetica; */
    font-family: "Inter", Helvetica;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -0.5px;
    position: relative;
    text-align: center;
    width: fit-content;
    }

    .google {
        align-items: center;
        border: 1px solid;
        border-color: var(--collection-1-neutral-50);
        border-radius: 6px;
        box-shadow: var(--shadow-1);
        display: flex;
        gap: 6px;
        justify-content: center;
        overflow: hidden;
        padding: 12px 20px;
        position: relative;
    }
    
    .google .apple {
        height: 16px;
        position: relative;
        width: 16px;
    }
    
    .google .text-wrapper {
        color: var(--collection-1-neutral-90);
        /* font-family: "Inter-SemiBold", Helvetica; */
        font-family: "Inter", Helvetica;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: normal;
        margin-top: -0.5px;
        position: relative;
        text-align: center;
        width: fit-content;
    }
    