.contact-nav a{
    text-decoration: none;
    color:black;
    border-bottom:2px solid white;
}

.contact-nav a:hover{
    border-bottom:2px solid var(--collection-1-primary-main);
}


.intern-image{
    width:70%
}