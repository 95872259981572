.w-35px{
    width:35px !important;
    height:35px !important;
}

.stroke{
    box-shadow:0 0 0 5px rgba(255,255,255,0.5);
}


.angle-180{
    transition: rotate(180deg);
}

.sticky-bar{
    position: sticky !important;
    top: 0 !important;
    background: #f83d23 !important;
    width: 100% !important;    
}