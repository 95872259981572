:root {
    --collection-1-danger-border: rgba(238, 180, 176, 1);
    --collection-1-danger-hover: rgba(189, 37, 28, 1);
    --collection-1-danger-main: rgba(203, 58, 49, 1);
    --collection-1-danger-pressed: rgba(115, 25, 18, 1);
    --collection-1-danger-surface: rgba(255, 244, 242, 1);
    --collection-1-info-border: rgba(177, 197, 246, 1);
    --collection-1-info-hover:#FF6D38;
    --collection-1-info-main:#FF6D38;
    --collection-1-info-pressed: #FF6D38;
    --collection-1-info-surface: rgba(240, 243, 255, 1);
    --collection-1-neutral-10: rgba(255, 255, 255, 1);
    --collection-1-neutral-100: rgba(10, 10, 10, 1);
    --collection-1-neutral-20: rgba(245, 245, 245, 1);
    --collection-1-neutral-30: rgba(237, 237, 237, 1);
    --collection-1-neutral-40: rgba(224, 224, 224, 1);
    --collection-1-neutral-50: rgba(194, 194, 194, 1);
    --collection-1-neutral-60: rgba(158, 158, 158, 1);
    --collection-1-neutral-70: rgba(117, 117, 117, 1);
    --collection-1-neutral-80: rgba(97, 97, 97, 1);
    --collection-1-neutral-90: rgba(64, 64, 64, 1);
    --collection-1-primary-border: rgba(223, 228, 242, 1);
    --collection-1-primary-focused: rgba(56, 112, 255, 0.2);
    --collection-1-primary-hover: #FF6D38;
    --collection-1-primary-main:#FF6D38;
    --collection-1-primary-main-mild:rgba(255, 109, 56, 0.7);
    --collection-1-primary-pressed: rgba(33, 58, 122, 1);
    --collection-1-primary-surface: rgba(245, 248, 255, 1);
    --collection-1-success-border: rgba(184, 219, 202, 1);
    --collection-1-success-hover: rgba(54, 122, 89, 1);
    --collection-1-success-main: rgba(67, 147, 108, 1);
    --collection-1-success-pressed: rgba(32, 87, 61, 1);
    --collection-1-success-surface: rgba(247, 247, 247, 1);
    --collection-1-warning-border: rgba(238, 206, 176, 1);
    --collection-1-warning-hover: rgba(191, 105, 25, 1);
    --collection-1-warning-main: rgba(205, 123, 46, 1);
    --collection-1-warning-pressed: rgba(115, 64, 17, 1);
    --collection-1-warning-surface: rgba(255, 249, 242, 1);
    --shadow-1: 0px 1px 2px 0px rgba(0, 0, 0, 0.12);
    --shadow-2: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    --shadow-3: ;
    --p-font:1.2rem;
    --h-font:2.1rem;
 --h-header-font:2.5rem;
    --h-tag-font:1.05rem;
    --footer-div:0.8rem;
    --footer-italic:0.8rem;
    --h-medium-font:1.5rem;
    --p-light:1.1rem;
    --p-medium:1rem; 
    --p-large:1.3rem;
    --p-miny:0.9rem;
    --h-large-font:1.8rem;
    --p-too-tiny:0.7rem;
}