.instruction {
    align-items: center;
    background-color: #fafbff;
    display: flex;
    gap: 56px;
    padding: 100px 150px 0px;
    position: relative;
}

.instruction .content {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 32px;
    justify-content: center;
    position: relative;
}

.instruction .title {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 14px;
    position: relative;
    width: 100%;
}

.instruction .title-2 {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
}

.instruction .text-wrapper-2 {
    align-self: stretch;
    color: var(--collection-1-success-main);
    font-family: "Inter", Helvetica;
    /* font-size: 20px; */
    font-size:var(--h-tag-font);
    font-weight: 500;
    letter-spacing: 0;
    line-height: 27.2px;
    margin-top: -1px;
    position: relative;
}

.instruction .which-segment-is {
    align-self: stretch;
    color: var(--collection-1-neutral-100);
    font-family: "Inter", Helvetica;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 48px;
    position: relative;
}

.instruction .span {
    color: #0a0a0a;
}

.instruction .text-wrapper-3 {
    color: #ff6d38;
    font-size: 32px;
    font-weight: 700;
}

.instruction .p {
    color: var(--collection-1-neutral-90);
    flex: 1;
    font-family: "Inter", Helvetica;
    font-size: var(--p-font);
    /* font-size: 1.2rem; */
    font-weight: 400;
    letter-spacing: 0;
    line-height: 30.6px;
    position: relative;
}

.instruction .content-2 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 32px;
    position: relative;
    width: 100%;
}

.instruction .selection-instance {
    background-image: url(../../../assets/image@2x.png) !important;
    overflow: hidden !important;
}

.instruction .steps {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 40px;
    position: relative;
}

.instruction .col {
    /* align-items: flex-start; */
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 24px;
    position: relative;
    width: 100%;
}

.instruction .step {
    align-items: flex-start;
    background-color: var(--collection-1-neutral-10);
    border: 1px solid;
    border-color: var(--collection-1-neutral-50);
    border-radius: 6px;
    box-shadow: var(--shadow-1);
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: min(10px,16px);
    overflow: hidden;
    padding: 14px;
    position: relative;
    max-width:400px;
}

.instruction .element-modern {
    align-self: stretch;
    height: 209px;
    object-fit: cover;
    position: relative;
    width: 100%;
}

.instruction .content-3 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 4px;
    justify-content: center;
    position: relative;
    width: 100%;
}

.instruction .text-wrapper-4 {
    align-self: stretch;
    color: var(--collection-1-primary-main);
    font-family: "Inter", Helvetica;
    /* font-size: 20px; */
    font-size:var(--p-large);
    font-weight: 600;
    letter-spacing: 0;
    line-height: 34px;
    margin-top: -1px;
    position: relative;
}

.instruction .text-wrapper-5 {
    align-self: stretch;
    color: var(--collection-1-neutral-90);
    font-family: "Inter", Helvetica;
    /* font-size: 16px; */
    font-size:var(--p-light);
    font-weight: 400;
    letter-spacing: 0;
    line-height: 27.2px;
    position: relative;
}

.selection-carousel{
    width:100% !important;
}

@media (min-width:1000px){
    .selection-carousel{
        width:fit-content !important;
    }
}

@media (max-width:1000px){
    .mobile-selection{
        width:90% !important;
        height:300px !important;
    }
}


.price-movement {
    top: 0;
    position: relative;
    animation: pricing 0.5s ease-in-out;
}

#price-movement1 {
    position: relative;
    /* top: 1000px; */
    top:0;
}


@keyframes pricing {
    0% {
        top: 1000px;
    }
    100% {
        top: 0; /* Move to 0px */
    }
}



@media(max-width:800px){
    .instruction{
        padding:2rem;
    }
}

@media (max-width:768px){
    #price-movement1 {
        position: relative;
        top: 0px;
    }
}