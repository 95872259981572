.text-wrapper-verify{
    color: var(--collection-1-neutral-90);
    flex: 1 1;
    font-family: "Inter-Regular", Helvetica;
    font-size: var(--p-font);
    font-weight: 400;
    letter-spacing: 0;
    line-height: 25.6px;
    margin-top: -1px;
    position: relative;
}

.verify-container .register:hover{
    background-color: white !important;
    border:3px solid var(--collection-1-primary-main) !important;
    color:var(--collection-1-primary-main) !important;
}

.bouncing-icons-container {
    display: flex;
  }
  
  .bouncing-icon1 {
    animation: bounce1 2s infinite alternate;
    margin: 0 10px;
  }
  

  .bouncing-icon2 {
    animation: bounce2 2s infinite alternate;
    margin: 0 10px;
  }
  
  .bouncing-icon3 {
    animation: bounce3 2s infinite alternate;
    margin: 0 10px;
  }
  
  @keyframes bounce1 {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
  
  @keyframes bounce2 {
    0%, 100% {
      transform: translateY(-10px);
    }
    50% {
      transform: translateY(0px);
    }
  }
  
  @keyframes bounce3 {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
  